import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBK = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2048 2048' {...props}>
    <path
      fill='#f9f9f9'
      d='m553 1485-55 320 1048 5-48-335s324-313 330-467c7-153-35-331-241-406-183-67-372 121-372 121l-182-161-190 160S658 586 587 592a373 373 0 0 0-362 333c10 331 328 560 328 560'
    />
    <path
      fill='#101010'
      d='M1024 1769h489l-12-73H547l-12 73zm0-921q-25-60-62-111 31-48 62-65 30 17 62 65-38 51-62 111m-97 454q-154 11-303 58-123-108-200-213t-77-202q0-89 74-159t148-70q67 0 135 63t102 130q30 54 75 175t46 218m-350 217-26 156 145-84zm447-907q-47 0-136 121-31-36-50-55 93-140 186-140 92 0 186 140-20 19-50 55-90-121-136-121m0 775q-1-126-42-267t-84-227l-14-27-12-23q-28-43-48-69-51-63-120-105t-134-42q-103 0-208 93T257 949q0 120 99 255t249 259q201-74 419-76m0 456H448l61-365q-325-280-326-535-1-159 125-274t267-116q78 0 159 47t142 119q61 74 99 165t49 150q12-60 49-150t99-165q61-72 142-119t159-47q140 0 266 116t126 274q-2 255-326 535l61 365zm97-541q0-97 45-218t76-175q34-68 102-130t135-63q74 0 148 70t74 159q0 96-77 202t-200 213q-150-47-303-58m350 217-119 72 145 84zm-447-132q217 2 419 76 150-125 249-259t99-255q0-136-105-229t-208-93q-66 0-135 42t-119 105q-21 26-48 69l-12 23-14 27q-44 85-85 227t-41 267m-139 159 139 86 139-84-139-86zm92-1248v-95h94v95h107v95h-107v153q-48-16-94 0V393H870v-95z'
    />
  </svg>
);
export default SvgBK;
