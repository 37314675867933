import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBB = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2048 2048' {...props}>
    <path fill='#f9f9f9' d='M732 1290 628 993l386-417 421 375-132 380 49 152-658-9z' />
    <path
      fill='#101010'
      d='M768 1365q-5 39-26 82h564q-18-36-26-82zm495-73 46-73q-142-49-285-47-144-2-285 47l46 73q118-40 239-38 120-2 239 38m-432 227H624q67-116 72-229-114-119-162-223t-6-224q33-96 118-189t312-247q-17-11-46-36t-29-79q0-58 41-96t100-38q58 0 100 38t41 96q0 54-29 79t-46 36q226 153 311 247t119 189q42 119-6 224t-162 223q4 113 72 229h-207l10 16q33 53 70 60t89 7h250q76 0 142 62t65 179h-495q-123 0-223-84t-101-199q0 114-101 199t-223 84H205q0-117 65-179t142-62h250q51 0 88-7t71-60zm146-701h-95v89h95v165h94V907h95v-89h-95V714h-94z'
    />
  </svg>
);
export default SvgBB;
