import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBR = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2048 2048' {...props}>
    <path fill='#f9f9f9' d='m674 732-76 807 851 14-75-833z' />
    <path
      fill='#101010'
      d='M1024 1843H383l29-264 159-118 50-659-149-107-17-341h289v147h137V354h286v147h137V354h289l-17 341-149 107 50 659 159 118 29 264zm0-989h333l-6-88H697l-6 88zm0 647h381l-6-87H649l-6 87z'
    />
  </svg>
);
export default SvgBR;
