//
// Copyright 2024 DXOS.org
//

export type FunctionDefinition = {
  name: string;
  description?: string;
  syntax?: string;
  section?: string;
};

/**
 * nullDate {year: 1899, month: 12, day: 30}
 */
export const defaultFunctions: FunctionDefinition[] = [
  // Array

  {
    name: 'ARRAYFORMULA',
    description: 'Enables the array arithmetic mode for a single formula.',
    syntax: 'ARRAYFORMULA(Formula)',
    section: 'Array',
  },
  {
    name: 'FILTER',
    description: 'Filters an array, based on multiple conditions (boolean arrays).',
    syntax: 'FILTER(SourceArray, BoolArray1, BoolArray2, ...BoolArrayN)',
    section: 'Array',
  },
  {
    name: 'ARRAY_CONSTRAIN',
    description: 'Truncates an array to given dimensions.',
    syntax: 'ARRAY_CONSTRAIN(Array, Height, Width)',
    section: 'Array',
  },

  // Date and time

  {
    name: 'DATE',
    description: 'Returns the specified date as the number of full days since nullDate.',
    syntax: 'DATE(Year, Month, Day)',
    section: 'Date and time',
  },
  {
    name: 'DATEDIF',
    description: 'Calculates distance between two dates, in provided unit parameter.',
    syntax: 'DATEDIF(Date1, Date2, Units)',
    section: 'Date and time',
  },
  {
    name: 'DATEVALUE',
    description: 'Parses a date string and returns it as the number of full days since nullDate.',
    syntax: 'DATEVALUE(Datestring)',
    section: 'Date and time',
  },
  {
    name: 'DAY',
    description: 'Returns the day of the given date value.',
    syntax: 'DAY(Number)',
    section: 'Date and time',
  },
  {
    name: 'DAYS',
    description: 'Calculates the difference between two date values.',
    syntax: 'DAYS(Date2, Date1)',
    section: 'Date and time',
  },
  {
    name: 'DAYS360',
    description: 'Calculates the difference between two date values in days, in 360-day basis.',
    syntax: 'DAYS360(Date2, Date1[, Format])',
    section: 'Date and time',
  },
  {
    name: 'EDATE',
    description:
      'Shifts the given startdate by given number of months and returns it as the number of full days since nullDate.',
    syntax: 'EDATE(Startdate, Months)',
    section: 'Date and time',
  },
  {
    name: 'EOMONTH',
    description: 'Returns the date of the last day of a month which falls months away from the start date.',
    syntax: 'EOMONTH(Startdate, Months)',
    section: 'Date and time',
  },
  {
    name: 'HOUR',
    description: 'Returns hour component of given time.',
    syntax: 'HOUR(Time)',
    section: 'Date and time',
  },
  {
    name: 'INTERVAL',
    description: 'Returns interval string from given number of seconds.',
    syntax: 'INTERVAL(Seconds)',
    section: 'Date and time',
  },
  {
    name: 'ISOWEEKNUM',
    description: 'Returns an ISO week number that corresponds to the week of year.',
    syntax: 'ISOWEEKNUM(Date)',
    section: 'Date and time',
  },
  {
    name: 'MINUTE',
    description: 'Returns minute component of given time.',
    syntax: 'MINUTE(Time)',
    section: 'Date and time',
  },
  {
    name: 'MONTH',
    description: 'Returns the month for the given date value.',
    syntax: 'MONTH(Number)',
    section: 'Date and time',
  },
  {
    name: 'NETWORKDAYS',
    description: 'Returns the number of working days between two given dates.',
    syntax: 'NETWORKDAYS(Date1, Date2[, Holidays])',
    section: 'Date and time',
  },
  {
    name: 'NETWORKDAYS.INTL',
    description: 'Returns the number of working days between two given dates.',
    syntax: 'NETWORKDAYS.INTL(Date1, Date2[, Mode [, Holidays]])',
    section: 'Date and time',
  },
  {
    name: 'NOW',
    description: 'Returns current date + time as a number of days since nullDate.',
    syntax: 'NOW()',
    section: 'Date and time',
  },
  {
    name: 'SECOND',
    description: 'Returns second component of given time.',
    syntax: 'SECOND(Time)',
    section: 'Date and time',
  },
  {
    name: 'TIME',
    description: 'Returns the number that represents a given time as a fraction of full day.',
    syntax: 'TIME(Hour, Minute, Second)',
    section: 'Date and time',
  },
  {
    name: 'TIMEVALUE',
    description: 'Parses a time string and returns a number that represents it as a fraction of a full day.',
    syntax: 'TIMEVALUE(Timestring)',
    section: 'Date and time',
  },
  {
    name: 'TODAY',
    description: 'Returns an integer representing the current date as the number of full days since nullDate.',
    syntax: 'TODAY()',
    section: 'Date and time',
  },
  {
    name: 'WEEKDAY',
    description: 'Computes a number between 1-7 representing the day of week.',
    syntax: 'WEEKDAY(Date, Type)',
    section: 'Date and time',
  },
  {
    name: 'WEEKNUM',
    description: 'Returns a week number that corresponds to the week of year.',
    syntax: 'WEEKNUM(Date, Type)',
    section: 'Date and time',
  },
  {
    name: 'WORKDAY',
    description: 'Returns the working day number of days from start day.',
    syntax: 'WORKDAY(Date, Shift[, Holidays])',
    section: 'Date and time',
  },
  {
    name: 'WORKDAY.INTL',
    description: 'Returns the working day number of days from start day.',
    syntax: 'WORKDAY.INTL(Date, Shift[, Mode[, Holidays]])',
    section: 'Date and time',
  },
  {
    name: 'YEAR',
    description: 'Returns the year as a number according to the internal calculation rules.',
    syntax: 'YEAR(Number)',
    section: 'Date and time',
  },
  {
    name: 'YEARFRAC',
    description: 'Computes the difference between two date values, in fraction of years.',
    syntax: 'YEARFRAC(Date2, Date1[, Format])',
    section: 'Date and time',
  },

  // Engineering

  {
    name: 'BIN2DEC',
    description: 'The result is the decimal number for the binary number entered.',
    syntax: 'BIN2DEC(Number)',
    section: 'Engineering',
  },
  {
    name: 'BIN2HEX',
    description: 'The result is the hexadecimal number for the binary number entered.',
    syntax: 'BIN2HEX(Number, Places)',
    section: 'Engineering',
  },
  {
    name: 'BIN2OCT',
    description: 'The result is the octal number for the binary number entered.',
    syntax: 'BIN2OCT(Number, Places)',
    section: 'Engineering',
  },
  {
    name: 'BITAND',
    description: "Returns a bitwise logical 'and' of the parameters.",
    syntax: 'BITAND(Number1, Number2)',
    section: 'Engineering',
  },
  {
    name: 'BITLSHIFT',
    description: 'Shifts a number left by n bits.',
    syntax: 'BITLSHIFT(Number, Shift)',
    section: 'Engineering',
  },
  {
    name: 'BITOR',
    description: "Returns a bitwise logical 'or' of the parameters.",
    syntax: 'BITOR(Number1, Number2)',
    section: 'Engineering',
  },
  {
    name: 'BITRSHIFT',
    description: 'Shifts a number right by n bits.',
    syntax: 'BITRSHIFT(Number, Shift)',
    section: 'Engineering',
  },
  {
    name: 'BITXOR',
    description: "Returns a bitwise logical 'exclusive or' of the parameters.",
    syntax: 'BITXOR(Number1, Number2)',
    section: 'Engineering',
  },
  {
    name: 'COMPLEX',
    description: 'Returns complex number from Re and Im parts.',
    syntax: 'COMPLEX(Re, Im[, Symbol])',
    section: 'Engineering',
  },
  {
    name: 'DEC2BIN',
    description: 'Returns the binary number for the decimal number entered between –512 and 511.',
    syntax: 'DEC2BIN(Number, Places)',
    section: 'Engineering',
  },
  {
    name: 'DEC2HEX',
    description: 'Returns the hexadecimal number for the decimal number entered.',
    syntax: 'DEC2HEX(Number, Places)',
    section: 'Engineering',
  },
  {
    name: 'DEC2OCT',
    description: 'Returns the octal number for the decimal number entered.',
    syntax: 'DEC2OCT(Number, Places)',
    section: 'Engineering',
  },
  {
    name: 'DELTA',
    description: 'Returns TRUE (1) if both numbers are equal, otherwise returns FALSE (0).',
    syntax: 'DELTA(Number_1, Number_2)',
    section: 'Engineering',
  },
  {
    name: 'ERF',
    description: 'Returns values of the Gaussian error integral.',
    syntax: 'ERF(Lower_Limit, Upper_Limit)',
    section: 'Engineering',
  },
  {
    name: 'ERFC',
    description: 'Returns complementary values of the Gaussian error integral between x and infinity.',
    syntax: 'ERFC(Lower_Limit)',
    section: 'Engineering',
  },
  {
    name: 'HEX2BIN',
    description: 'The result is the binary number for the hexadecimal number entered.',
    syntax: 'HEX2BIN(Number, Places)',
    section: 'Engineering',
  },
  {
    name: 'HEX2DEC',
    description: 'The result is the decimal number for the hexadecimal number entered.',
    syntax: 'HEX2DEC(Number)',
    section: 'Engineering',
  },
  {
    name: 'HEX2OCT',
    description: 'The result is the octal number for the hexadecimal number entered.',
    syntax: 'HEX2OCT(Number, Places)',
    section: 'Engineering',
  },
  {
    name: 'IMABS',
    description: 'Returns module of a complex number.',
    syntax: 'IMABS(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMAGINARY',
    description: 'Returns imaginary part of a complex number.',
    syntax: 'IMAGINARY(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMARGUMENT',
    description: 'Returns argument of a complex number.',
    syntax: 'IMARGUMENT(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMCONJUGATE',
    description: 'Returns conjugate of a complex number.',
    syntax: 'IMCONJUGATE(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMCOS',
    description: 'Returns cosine of a complex number.',
    syntax: 'IMCOS(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMCOSH',
    description: 'Returns hyperbolic cosine of a complex number.',
    syntax: 'IMCOSH(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMCOT',
    description: 'Returns cotangens of a complex number.',
    syntax: 'IMCOT(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMCSC',
    description: 'Returns cosecans of a complex number.',
    syntax: 'IMCSC(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMCSCH',
    description: 'Returns hyperbolic cosecans of a complex number.',
    syntax: 'IMCSCH(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMDIV',
    description: 'Divides two complex numbers.',
    syntax: 'IMDIV(Complex1, Complex2)',
    section: 'Engineering',
  },
  {
    name: 'IMEXP',
    description: 'Returns exponent of a complex number.',
    syntax: 'IMEXP(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMLN',
    description: 'Returns natural logarithm of a complex number.',
    syntax: 'IMLN(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMLOG2',
    description: 'Returns binary logarithm of a complex number.',
    syntax: 'IMLOG2(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMLOG10',
    description: 'Returns base-10 logarithm of a complex number.',
    syntax: 'IMLOG10(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMPOWER',
    description: 'Returns a complex number raised to a given power.',
    syntax: 'IMPOWER(Complex, Number)',
    section: 'Engineering',
  },
  {
    name: 'IMPRODUCT',
    description: 'Multiplies complex numbers.',
    syntax: 'IMPRODUCT(Complex1, Complex2, ...ComplexN)',
    section: 'Engineering',
  },
  {
    name: 'IMREAL',
    description: 'Returns real part of a complex number.',
    syntax: 'IMREAL(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMSEC',
    description: 'Returns the secant of a complex number.',
    syntax: 'IMSEC(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMSECH',
    description: 'Returns the hyperbolic secant of a complex number.',
    syntax: 'IMSECH(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMSIN',
    description: 'Returns sine of a complex number.',
    syntax: 'IMSIN(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMSINH',
    description: 'Returns hyperbolic sine of a complex number.',
    syntax: 'IMSINH(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMSQRT',
    description: 'Returns a square root of a complex number.',
    syntax: 'IMSQRT(Complex)',
    section: 'Engineering',
  },
  {
    name: 'IMSUB',
    description: 'Subtracts two complex numbers.',
    syntax: 'IMSUB(Complex1, Complex2)',
    section: 'Engineering',
  },
  {
    name: 'IMSUM',
    description: 'Adds complex numbers.',
    syntax: 'IMSUM(Complex1, Complex2, ..ComplexN)',
    section: 'Engineering',
  },
  {
    name: 'IMTAN',
    description: 'Returns the tangent of a complex number.',
    syntax: 'IMTAN(Complex)',
    section: 'Engineering',
  },
  {
    name: 'OCT2BIN',
    description: 'The result is the binary number for the octal number entered.',
    syntax: 'OCT2BIN(Number, Places)',
    section: 'Engineering',
  },
  {
    name: 'OCT2DEC',
    description: 'The result is the decimal number for the octal number entered.',
    syntax: 'OCT2DEC(Number)',
    section: 'Engineering',
  },
  {
    name: 'OCT2HEX',
    description: 'The result is the hexadecimal number for the octal number entered.',
    syntax: 'OCT2HEX(Number, Places)',
    section: 'Engineering',
  },

  // Information

  {
    name: 'ISBINARY',
    description: 'Returns TRUE if provided value is a valid binary number.',
    syntax: 'ISBINARY(Value)',
    section: 'Information',
  },
  {
    name: 'ISBLANK',
    description: 'Returns TRUE if the reference to a cell is blank.',
    syntax: 'ISBLANK(Value)',
    section: 'Information',
  },
  {
    name: 'ISERR',
    description: 'Returns TRUE if the value is error value except #N/A!.',
    syntax: 'ISERR(Value)',
    section: 'Information',
  },
  {
    name: 'ISERROR',
    description: 'Returns TRUE if the value is general error value.',
    syntax: 'ISERROR(Value)',
    section: 'Information',
  },
  {
    name: 'ISEVEN',
    description: 'Returns TRUE if the value is an even integer, or FALSE if the value is odd.',
    syntax: 'ISEVEN(Value)',
    section: 'Information',
  },
  {
    name: 'ISFORMULA',
    description: 'Checks whether referenced cell is a formula.',
    syntax: 'ISFORMULA(Value)',
    section: 'Information',
  },
  {
    name: 'ISLOGICAL',
    description: 'Tests for a logical value (TRUE or FALSE).',
    syntax: 'ISLOGICAL(Value)',
    section: 'Information',
  },
  {
    name: 'ISNA',
    description: 'Returns TRUE if the value is #N/A! error.',
    syntax: 'ISNA(Value)',
    section: 'Information',
  },
  {
    name: 'ISNONTEXT',
    description: 'Tests if the cell contents are text or numbers, and returns FALSE if the contents are text.',
    syntax: 'ISNONTEXT(Value)',
    section: 'Information',
  },
  {
    name: 'ISNUMBER',
    description: 'Returns TRUE if the value refers to a number.',
    syntax: 'ISNUMBER(Value)',
    section: 'Information',
  },
  {
    name: 'ISODD',
    description: 'Returns TRUE if the value is odd, or FALSE if the number is even.',
    syntax: 'ISODD(Value)',
    section: 'Information',
  },
  {
    name: 'ISREF',
    description: 'Returns TRUE if provided value is #REF! error.',
    syntax: 'ISREF(Value)',
    section: 'Information',
  },
  {
    name: 'ISTEXT',
    description: 'Returns TRUE if the cell contents reference text.',
    syntax: 'ISTEXT(Value)',
    section: 'Information',
  },
  {
    name: 'SHEET',
    description: 'Returns sheet number of a given value or a formula sheet number if no argument is provided.',
    syntax: 'SHEET([Value])',
    section: 'Information',
  },
  {
    name: 'SHEETS',
    description:
      'Returns number of sheet of a given reference or number of all sheets in workbook when no argument is provided.',
    syntax: 'SHEETS([Value])',
    section: 'Information',
  },
  {
    name: 'NA',
    description: 'Returns #N/A! error value.',
    syntax: 'NA(Value)',
    section: 'Information',
  },

  // Financial

  {
    name: 'CUMIPMT',
    description: 'Returns the cumulative interest paid on a loan between a start period and an end period.',
    syntax: 'CUMIPMT(Rate, Nper, Pv, Start, End, type)',
    section: 'Financial',
  },
  {
    name: 'CUMPRINC',
    description: 'Returns the cumulative principal paid on a loan between a start period and an end period.',
    syntax: 'CUMPRINC(Rate, Nper, Pv, Start, End, Type)',
    section: 'Financial',
  },
  {
    name: 'DB',
    description: 'Returns the depreciation of an asset for a period using the fixed-declining balance method.',
    syntax: 'DB(Cost, Salvage, Life, Period[, Month])',
    section: 'Financial',
  },
  {
    name: 'DDB',
    description: 'Returns the depreciation of an asset for a period using the double-declining balance method.',
    syntax: 'DDB(Cost, Salvage, Life, Period[, Factor])',
    section: 'Financial',
  },
  {
    name: 'DOLLARDE',
    description: 'Converts a price entered with a special notation to a price displayed as a decimal number.',
    syntax: 'DOLLARDE(Price, Fraction)',
    section: 'Financial',
  },
  {
    name: 'DOLLARFR',
    description: 'Converts a price displayed as a decimal number to a price entered with a special notation.',
    syntax: 'DOLLARFR(Price, Fraction)',
    section: 'Financial',
  },
  {
    name: 'EFFECT',
    description:
      'Calculates the effective annual interest rate from a nominal interest rate and the number of compounding periods per year.',
    syntax: 'EFFECT(Nominal_rate, Npery)',
    section: 'Financial',
  },
  {
    name: 'FV',
    description: 'Returns the future value of an investment.',
    syntax: 'FV(Rate, Nper, Pmt[, Pv,[ Type]])',
    section: 'Financial',
  },
  {
    name: 'FVSCHEDULE',
    description: 'Returns the future value of an investment based on a rate schedule.',
    syntax: 'FVSCHEDULE(Pv, Schedule)',
    section: 'Financial',
  },
  {
    name: 'IPMT',
    description: 'Returns the interest portion of a given loan payment in a given payment period.',
    syntax: 'IPMT(Rate, Per, Nper, Pv[, Fv[, Type]])',
    section: 'Financial',
  },
  {
    name: 'ISPMT',
    description: 'Returns the interest paid for a given period of an investment with equal principal payments.',
    syntax: 'ISPMT(Rate, Per, Nper, Value)',
    section: 'Financial',
  },
  {
    name: 'MIRR',
    description: 'Returns modified internal value for cashflows.',
    syntax: 'MIRR(Flows, FRate, RRate)',
    section: 'Financial',
  },
  {
    name: 'NOMINAL',
    description: 'Returns the nominal interest rate.',
    syntax: 'NOMINAL(Effect_rate, Npery)',
    section: 'Financial',
  },
  {
    name: 'NPER',
    description:
      'Returns the number of periods for an investment assuming periodic, constant payments and a constant interest rate.',
    syntax: 'NPER(Rate, Pmt, Pv[, Fv[, Type]])',
    section: 'Financial',
  },
  {
    name: 'NPV',
    description: 'Returns net present value.',
    syntax: 'NPV(Rate, Value1, Value2, ...ValueN)',
    section: 'Financial',
  },
  {
    name: 'PDURATION',
    description: 'Returns number of periods to reach specific value.',
    syntax: 'PDURATION(Rate, Pv, Fv)',
    section: 'Financial',
  },
  {
    name: 'PMT',
    description: 'Returns the periodic payment for a loan.',
    syntax: 'PMT(Rate, Nper, Pv[, Fv[, Type]])',
    section: 'Financial',
  },
  {
    name: 'PPMT',
    description: 'Calculates the principal portion of a given loan payment.',
    syntax: 'PPMT(Rate, Per, Nper, Pv[, Fv[, Type]])',
    section: 'Financial',
  },
  {
    name: 'PV',
    description: 'Returns the present value of an investment.',
    syntax: 'PV(Rate, Nper, Pmt[, Fv[, Type]])',
    section: 'Financial',
  },
  {
    name: 'RATE',
    description: 'Returns the interest rate per period of an annuity.',
    syntax: 'RATE(Nper, Pmt, Pv[, Fv[, Type[, guess]]])',
    section: 'Financial',
  },
  {
    name: 'RRI',
    description: 'Returns an equivalent interest rate for the growth of an investment.',
    syntax: 'RRI(Nper, Pv, Fv)',
    section: 'Financial',
  },
  {
    name: 'SLN',
    description: 'Returns the depreciation of an asset for one period, based on a straight-line method.',
    syntax: 'SLN(Cost, Salvage, Life)',
    section: 'Financial',
  },
  {
    name: 'SYD',
    description: "Returns the 'sum-of-years' depreciation for an asset in a period.",
    syntax: 'SYD(Cost, Salvage, Life, Period)',
    section: 'Financial',
  },
  {
    name: 'TBILLEQ',
    description: 'Returns the bond-equivalent yield for a Treasury bill.',
    syntax: 'TBILLEQ(Settlement, Maturity, Discount)',
    section: 'Financial',
  },
  {
    name: 'TBILLPRICE',
    description: 'Returns the price per $100 face value for a Treasury bill.',
    syntax: 'TBILLPRICE(Settlement, Maturity, Discount)',
    section: 'Financial',
  },
  {
    name: 'TBILLYIELD',
    description: 'Returns the yield for a Treasury bill.',
    syntax: 'TBILLYIELD(Settlement, Maturity, Price)',
    section: 'Financial',
  },
  {
    name: 'XNPV',
    description: 'Returns net present value.',
    syntax: 'XNPV(Rate, Payments, Dates)',
    section: 'Financial',
  },

  // Logical

  {
    name: 'AND',
    description: 'Returns TRUE if all arguments are TRUE.',
    syntax: 'AND(Logical_value1, Logical_value2, ...Logical_valueN)',
    section: 'Logical',
  },
  {
    name: 'FALSE',
    description: 'Returns the logical value FALSE.',
    syntax: 'FALSE()',
    section: 'Logical',
  },
  {
    name: 'IF',
    description: 'Specifies a logical test to be performed.',
    syntax: 'IF(Test, Then_value, Otherwise_value)',
    section: 'Logical',
  },
  {
    name: 'IFS',
    description: 'Evaluates multiple logical tests and returns a value that corresponds to the first true condition.',
    syntax: 'IFS(Condition1, Value1 [, Condition2, Value2 [, ...ConditionN, ValueN]])',
    section: 'Logical',
  },
  {
    name: 'IFNA',
    description:
      'Returns the value if the cell does not contains the #N/A (value not available) error value, or the alternative value if it does.',
    syntax: 'IFNA(Value, Alternate_value)',
    section: 'Logical',
  },
  {
    name: 'IFERROR',
    description: 'Returns the value if the cell does not contains an error value, or the alternative value if it does.',
    syntax: 'IFERROR(Value, Alternate_value)',
    section: 'Logical',
  },
  {
    name: 'NOT',
    description: 'Complements (inverts) a logical value.',
    syntax: 'NOT(Logicalvalue)',
    section: 'Logical',
  },
  {
    name: 'SWITCH',
    description: 'Evaluates a list of arguments, consisting of an expression followed by a value.',
    syntax: 'SWITCH(Expression1, Value1 [, Expression2, Value2 [, ...ExpressionN, ValueN]])',
    section: 'Logical',
  },
  {
    name: 'OR',
    description: 'Returns TRUE if at least one argument is TRUE.',
    syntax: 'OR(Logical_value1, Logical_value2, ...Logical_valueN)',
    section: 'Logical',
  },
  {
    name: 'TRUE',
    description: 'The logical value is set to TRUE.',
    syntax: 'TRUE()',
    section: 'Logical',
  },
  {
    name: 'XOR',
    description: 'Returns true if an odd number of arguments evaluates to TRUE.',
    syntax: 'XOR(Logical_value1, Logical_value2, ...Logical_valueN)',
    section: 'Logical',
  },

  // Lookup and reference

  {
    name: 'ADDRESS',
    description: 'Returns a cell reference as a string.',
    syntax: 'ADDRESS(Row, Column[, AbsoluteRelativeMode[, UseA1Notation[, Sheet]]])',
    section: 'Lookup and reference',
  },
  {
    name: 'CHOOSE',
    description: 'Uses an index to return a value from a list of values.',
    syntax: 'CHOOSE(Index, Value1, Value2, ...ValueN)',
    section: 'Lookup and reference',
  },
  {
    name: 'COLUMN',
    description: 'Returns column number of a given reference or formula reference if argument not provided.',
    syntax: 'COLUMNS([Reference])',
    section: 'Lookup and reference',
  },
  {
    name: 'COLUMNS',
    description: 'Returns the number of columns in the given reference.',
    syntax: 'COLUMNS(Array)',
    section: 'Lookup and reference',
  },
  {
    name: 'FORMULATEXT',
    description: 'Returns a formula in a given cell as a string.',
    syntax: 'FORMULATEXT(Reference)',
    section: 'Lookup and reference',
  },
  {
    name: 'HLOOKUP',
    description: 'Searches horizontally with reference to adjacent cells to the bottom.',
    syntax: 'HLOOKUP(Search_Criterion, Array, Index, Sort_Order)',
    section: 'Lookup and reference',
  },
  {
    name: 'HYPERLINK',
    description: "Stores the url in the cell's metadata.",
    syntax: 'HYPERLINK(Url[, LinkLabel])',
    section: 'Lookup and reference',
  },
  {
    name: 'INDEX',
    description:
      'Returns the contents of a cell specified by row and column number. The column number is optional and defaults to 1.',
    syntax: 'INDEX(Range, Row [, Column])',
    section: 'Lookup and reference',
  },
  {
    name: 'MATCH',
    description: 'Returns the relative position of an item in an array that matches a specified value.',
    syntax: 'MATCH(Searchcriterion, Lookuparray [, MatchType])',
    section: 'Lookup and reference',
  },
  {
    name: 'OFFSET',
    description:
      'Returns the value of a cell offset by a certain number of rows and columns from a given reference point.',
    syntax: 'OFFSET(Reference, Rows, Columns, Height, Width)',
    section: 'Lookup and reference',
  },
  {
    name: 'ROW',
    description: 'Returns row number of a given reference or formula reference if argument not provided.',
    syntax: 'ROW([Reference])',
    section: 'Lookup and reference',
  },
  {
    name: 'ROWS',
    description: 'Returns the number of rows in the given reference.',
    syntax: 'ROWS(Array)',
    section: 'Lookup and reference',
  },
  {
    name: 'VLOOKUP',
    description: 'Searches vertically with reference to adjacent cells to the right.',
    syntax: 'VLOOKUP(Search_Criterion, Array, Index, Sort_Order)',
    section: 'Lookup and reference',
  },

  // Math and trigonometry

  {
    name: 'ABS',
    description: 'Returns the absolute value of a number.',
    syntax: 'ABS(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ACOS',
    description: 'Returns the inverse trigonometric cosine of a number.',
    syntax: 'ACOS(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ACOSH',
    description: 'Returns the inverse hyperbolic cosine of a number.',
    syntax: 'ACOSH(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ACOT',
    description: 'Returns the inverse trigonometric cotangent of a number.',
    syntax: 'ACOT(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ACOTH',
    description: 'Returns the inverse hyperbolic cotangent of a number.',
    syntax: 'ACOTH(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ARABIC',
    description: 'Converts number from roman form.',
    syntax: 'ARABIC(String)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ASIN',
    description: 'Returns the inverse trigonometric sine of a number.',
    syntax: 'ASIN(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ASINH',
    description: 'Returns the inverse hyperbolic sine of a number.',
    syntax: 'ASINH(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ATAN',
    description: 'Returns the inverse trigonometric tangent of a number.',
    syntax: 'ATAN(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ATAN2',
    description: 'Returns the inverse trigonometric tangent of the specified x and y coordinates.',
    syntax: 'ATAN2(Numberx, Numbery)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ATANH',
    description: 'Returns the inverse hyperbolic tangent of a number.',
    syntax: 'ATANH(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'BASE',
    description: 'Converts a positive integer to a specified base into a text from the numbering system.',
    syntax: 'BASE(Number, Radix, [Minimumlength])',
    section: 'Math and trigonometry',
  },
  {
    name: 'CEILING',
    description: 'Rounds a number up to the nearest multiple of Significance.',
    syntax: 'CEILING(Number, Significance)',
    section: 'Math and trigonometry',
  },
  {
    name: 'CEILING.MATH',
    description: 'Rounds a number up to the nearest multiple of Significance.',
    syntax: 'CEILING.MATH(Number[, Significance[, Mode]])',
    section: 'Math and trigonometry',
  },
  {
    name: 'CEILING.PRECISE',
    description: 'Rounds a number up to the nearest multiple of Significance.',
    syntax: 'CEILING.PRECISE(Number[, Significance])',
    section: 'Math and trigonometry',
  },
  {
    name: 'COMBIN',
    description: 'Returns number of combinations (without repetitions).',
    syntax: 'COMBIN(Number, Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'COMBINA',
    description: 'Returns number of combinations (with repetitions).',
    syntax: 'COMBINA(Number, Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'COS',
    description: 'Returns the cosine of the given angle (in radians).',
    syntax: 'COS(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'COSH',
    description: 'Returns the hyperbolic cosine of the given value.',
    syntax: 'COSH(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'COT',
    description: 'Returns the cotangent of the given angle (in radians).',
    syntax: 'COT(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'COTH',
    description: 'Returns the hyperbolic cotangent of the given value.',
    syntax: 'COTH(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'COUNTUNIQUE',
    description: 'Counts the number of unique values in a list of specified values and ranges.',
    syntax: 'COUNTUNIQUE(Value1, Value2, ...ValueN)',
    section: 'Math and trigonometry',
  },
  {
    name: 'CSC',
    description: 'Returns the cosecans of the given angle (in radians).',
    syntax: 'CSC(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'CSCH',
    description: 'Returns the hyperbolic cosecant of the given value.',
    syntax: 'CSCH(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'DECIMAL',
    description: 'Converts text with characters from a number system to a positive integer in the base radix given.',
    syntax: 'DECIMAL("Text", Radix)',
    section: 'Math and trigonometry',
  },
  {
    name: 'DEGREES',
    description: 'Converts radians into degrees.',
    syntax: 'DEGREES(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'EVEN',
    description:
      'Rounds a positive number up to the next even integer and a negative number down to the next even integer.',
    syntax: 'EVEN(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'EXP',
    description: 'Returns constant e raised to the power of a number.',
    syntax: 'EXP(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'FACT',
    description: 'Returns a factorial of a number.',
    syntax: 'FACT(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'FACTDOUBLE',
    description: 'Returns a double factorial of a number.',
    syntax: 'FACTDOUBLE(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'FLOOR',
    description: 'Rounds a number down to the nearest multiple of Significance.',
    syntax: 'FLOOR(Number, Significance)',
    section: 'Math and trigonometry',
  },
  {
    name: 'FLOOR.MATH',
    description: 'Rounds a number down to the nearest multiple of Significance.',
    syntax: 'FLOOR.MATH(Number[, Significance[, Mode]])',
    section: 'Math and trigonometry',
  },
  {
    name: 'FLOOR.PRECISE',
    description: 'Rounds a number down to the nearest multiple of Significance.',
    syntax: 'FLOOR.PRECISE(Number[, Significance])',
    section: 'Math and trigonometry',
  },
  {
    name: 'GCD',
    description: 'Computes greatest common divisor of numbers.',
    syntax: 'GCD(Number1, Number2, ...NumberN)',
    section: 'Math and trigonometry',
  },
  {
    name: 'INT',
    description: 'Rounds a number down to the nearest integer.',
    syntax: 'INT(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ISO.CEILING',
    description: 'Rounds a number up to the nearest multiple of Significance.',
    syntax: 'ISO.CEILING(Number[, Significance])',
    section: 'Math and trigonometry',
  },
  {
    name: 'LCM',
    description: 'Computes least common multiplicity of numbers.',
    syntax: 'LCM(Number1, Number2, ...NumberN)',
    section: 'Math and trigonometry',
  },
  {
    name: 'LN',
    description: 'Returns the natural logarithm based on the constant e of a number.',
    syntax: 'LN(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'LOG',
    description: 'Returns the logarithm of a number to the specified base.',
    syntax: 'LOG(Number, Base)',
    section: 'Math and trigonometry',
  },
  {
    name: 'LOG10',
    description: 'Returns the base-10 logarithm of a number.',
    syntax: 'LOG10(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'MOD',
    description: 'Returns the remainder when one integer is divided by another.',
    syntax: 'MOD(Dividend, Divisor)',
    section: 'Math and trigonometry',
  },
  {
    name: 'MROUND',
    description: 'Rounds number to the nearest multiplicity.',
    syntax: 'MROUND(Number, Base)',
    section: 'Math and trigonometry',
  },
  {
    name: 'MULTINOMIAL',
    description: 'Returns number of multiset combinations.',
    syntax: 'MULTINOMIAL(Number1, Number2, ...NumberN)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ODD',
    description:
      'Rounds a positive number up to the nearest odd integer and a negative number down to the nearest odd integer.',
    syntax: 'ODD(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'PI',
    description: 'Returns 3.14159265358979, the value of the mathematical constant PI to 14 decimal places.',
    syntax: 'PI()',
    section: 'Math and trigonometry',
  },
  {
    name: 'POWER',
    description: 'Returns a number raised to another number.',
    syntax: 'POWER(Base, Exponent)',
    section: 'Math and trigonometry',
  },
  {
    name: 'PRODUCT',
    description: 'Returns product of numbers.',
    syntax: 'PRODUCT(Number1, Number2, ...NumberN)',
    section: 'Math and trigonometry',
  },
  {
    name: 'QUOTIENT',
    description: 'Returns integer part of a division.',
    syntax: 'QUOTIENT(Dividend, Divisor)',
    section: 'Math and trigonometry',
  },
  {
    name: 'RADIANS',
    description: 'Converts degrees to radians.',
    syntax: 'RADIANS(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'RAND',
    description: 'Returns a random number between 0 and 1.',
    syntax: 'RAND()',
    section: 'Math and trigonometry',
  },
  {
    name: 'RANDBETWEEN',
    description: 'Returns a random integer between two numbers.',
    syntax: 'RAND(Lowerbound, Upperbound)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ROMAN',
    description: 'Converts number to roman form.',
    syntax: 'ROMAN(Number[, Mode])',
    section: 'Math and trigonometry',
  },
  {
    name: 'ROUND',
    description: 'Rounds a number to a certain number of decimal places.',
    syntax: 'ROUND(Number, Count)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ROUNDDOWN',
    description: 'Rounds a number down, toward zero, to a certain precision.',
    syntax: 'ROUNDDOWN(Number, Count)',
    section: 'Math and trigonometry',
  },
  {
    name: 'ROUNDUP',
    description: 'Rounds a number up, away from zero, to a certain precision.',
    syntax: 'ROUNDUP(Number, Count)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SEC',
    description: 'Returns the secant of the given angle (in radians).',
    syntax: 'SEC(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SECH',
    description: 'Returns the hyperbolic secant of the given angle (in radians).',
    syntax: 'SEC(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SERIESSUM',
    description: 'Evaluates series at a point.',
    syntax: 'SERIESSUM(Number, Number, Number, Coefficients)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SIN',
    description: 'Returns the sine of the given angle (in radians).',
    syntax: 'SIN(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SINH',
    description: 'Returns the hyperbolic sine of the given value.',
    syntax: 'SINH(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SIGN',
    description: 'Returns sign of a number.',
    syntax: 'SIGN(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SQRT',
    description: 'Returns the positive square root of a number.',
    syntax: 'SQRT(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SQRTPI',
    description: 'Returns sqrt of number times pi.',
    syntax: 'SQRTPI(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SUBTOTAL',
    description: 'Computes aggregation using function specified by number.',
    syntax: 'SUBTOTAL(Function, Number1, Number2, ...NumberN)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SUM',
    description: 'Sums up the values of the specified cells.',
    syntax: 'SUM(Number1, Number2, ...NumberN)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SUMIF',
    description: 'Sums up the values of cells that belong to the specified range and meet the specified condition.',
    syntax: 'SUMIF(Range, Criteria, Sumrange)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SUMIFS',
    description:
      'Sums up the values of cells that belong to the specified range and meet the specified sets of conditions.',
    syntax:
      'SUMIFS(Sum_Range, Criterion_range1, Criterion1 [, Criterion_range2, Criterion2 [, ...Criterion_rangeN, CriterionN]])',
    section: 'Math and trigonometry',
  },
  {
    name: 'SUMPRODUCT',
    description: 'Multiplies corresponding elements in the given arrays, and returns the sum of those products.',
    syntax: 'SUMPRODUCT(Array1, Array2, ...ArrayN)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SUMSQ',
    description: 'Returns the sum of the squares of the arguments',
    syntax: 'SUMSQ(Number1, Number2, ...NumberN)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SUMX2MY2',
    description: 'Returns the sum of the square differences.',
    syntax: 'SUMX2MY2(Range1, Range2)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SUMX2PY2',
    description: 'Returns the sum of the square sums.',
    syntax: 'SUMX2PY2(Range1, Range2)',
    section: 'Math and trigonometry',
  },
  {
    name: 'SUMXMY2',
    description: 'Returns the sum of the square of differences.',
    syntax: 'SUMXMY2(Range1, Range2)',
    section: 'Math and trigonometry',
  },
  {
    name: 'TAN',
    description: 'Returns the tangent of the given angle (in radians).',
    syntax: 'TAN(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'TANH',
    description: 'Returns the hyperbolic tangent of the given value.',
    syntax: 'TANH(Number)',
    section: 'Math and trigonometry',
  },
  {
    name: 'TRUNC',
    description: 'Truncates a number by removing decimal places.',
    syntax: 'TRUNC(Number, Count)',
    section: 'Math and trigonometry',
  },

  // Matrix functions

  {
    name: 'MMULT',
    description: 'Calculates the array product of two arrays.',
    syntax: 'MMULT(Array, Array)',
    section: 'Matrix functions',
  },
  {
    name: 'MEDIANPOOL',
    description:
      'Calculates a smaller range which is a median of a Window_size, in a given Range, for every Stride element.',
    syntax: 'MEDIANPOOL(Range, Window_size, Stride)',
    section: 'Matrix functions',
  },
  {
    name: 'MAXPOOL',
    description:
      'Calculates a smaller range which is a maximum of a Window_size, in a given Range, for every Stride element.',
    syntax: 'MAXPOOL(Range, Window_size, Stride)',
    section: 'Matrix functions',
  },
  {
    name: 'TRANSPOSE',
    description: 'Transposes the rows and columns of an array.',
    syntax: 'TRANSPOSE(Array)',
    section: 'Matrix functions',
  },

  // Operator

  {
    name: 'HF.ADD',
    description: 'Adds two values.',
    syntax: 'HF.ADD(Number, Number)',
    section: 'Operator',
  },
  {
    name: 'HF.CONCAT',
    description: 'Concatenates two strings.',
    syntax: 'HF.CONCAT(String, String)',
    section: 'Operator',
  },
  {
    name: 'HF.DIVIDE',
    description: 'Divides two values.',
    syntax: 'HF.DIVIDE(Number, Number)',
    section: 'Operator',
  },
  {
    name: 'HF.EQ',
    description: 'Tests two values for equality.',
    syntax: 'HF.EQ(Value, Value)',
    section: 'Operator',
  },
  {
    name: 'HF.LTE',
    description: 'Tests two values for less-equal relation.',
    syntax: 'HF.LEQ(Value, Value)',
    section: 'Operator',
  },
  {
    name: 'HF.LT',
    description: 'Tests two values for less-than relation.',
    syntax: 'HF.LT(Value, Value)',
    section: 'Operator',
  },
  {
    name: 'HF.GTE',
    description: 'Tests two values for greater-equal relation.',
    syntax: 'HF.GEQ(Value, Value)',
    section: 'Operator',
  },
  {
    name: 'HF.GT',
    description: 'Tests two values for greater-than relation.',
    syntax: 'HF.GT(Value, Value)',
    section: 'Operator',
  },
  {
    name: 'HF.MINUS',
    description: 'Subtracts two values.',
    syntax: 'HF.MINUS(Number, Number)',
    section: 'Operator',
  },
  {
    name: 'HF.MULTIPLY',
    description: 'Multiplies two values.',
    syntax: 'HF.MULTIPLY(Number, Number)',
    section: 'Operator',
  },
  {
    name: 'HF.NE',
    description: 'Tests two values for inequality.',
    syntax: 'HF.NE(Value, Value)',
    section: 'Operator',
  },
  {
    name: 'HF.POW',
    description: 'Computes power of two values.',
    syntax: 'HF.POW(Number, Number)',
    section: 'Operator',
  },
  {
    name: 'HF.UMINUS',
    description: 'Negates the value.',
    syntax: 'HF.UMINUS(Number)',
    section: 'Operator',
  },
  {
    name: 'HF.UNARY_PERCENT',
    description: 'Applies percent operator.',
    syntax: 'HF.UNARY_PERCENT(Number)',
    section: 'Operator',
  },
  {
    name: 'HF.UPLUS',
    description: 'Applies unary plus.',
    syntax: 'HF.UPLUS(Number)',
    section: 'Operator',
  },

  // Statistical

  {
    name: 'AVEDEV',
    description: 'Returns the average deviation of the arguments.',
    syntax: 'AVEDEV(Number1, Number2, ...NumberN)',
    section: 'Statistical',
  },
  {
    name: 'AVERAGE',
    description: 'Returns the average of the arguments.',
    syntax: 'AVERAGE(Number1, Number2, ...NumberN)',
    section: 'Statistical',
  },
  {
    name: 'AVERAGEA',
    description: 'Returns the average of the arguments.',
    syntax: 'AVERAGEA(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'AVERAGEIF',
    description: 'Returns the arithmetic mean of all cells in a range that satisfy a given condition.',
    syntax: 'AVERAGEIF(Range, Criterion [, Average_Range ])',
    section: 'Statistical',
  },
  {
    name: 'BESSELI',
    description: 'Returns value of Bessel function.',
    syntax: 'BESSELI(x, n)',
    section: 'Statistical',
  },
  {
    name: 'BESSELJ',
    description: 'Returns value of Bessel function.',
    syntax: 'BESSELJ(x, n)',
    section: 'Statistical',
  },
  {
    name: 'BESSELK',
    description: 'Returns value of Bessel function.',
    syntax: 'BESSELK(x, n)',
    section: 'Statistical',
  },
  {
    name: 'BESSELY',
    description: 'Returns value of Bessel function.',
    syntax: 'BESSELY(x, n)',
    section: 'Statistical',
  },
  {
    name: 'BETA.DIST',
    description: 'Returns the density of Beta distribution.',
    syntax: 'BETA.DIST(Number1, Number2, Number3, Boolean[, Number4[, Number5]])',
    section: 'Statistical',
  },
  {
    name: 'BETADIST',
    description: 'Returns the density of Beta distribution.',
    syntax: 'BETADIST(Number1, Number2, Number3, Boolean[, Number4[, Number5]])',
    section: 'Statistical',
  },
  {
    name: 'BETA.INV',
    description: 'Returns the inverse Beta distribution value.',
    syntax: 'BETA.INV(Number1, Number2, Number3[, Number4[, Number5]])',
    section: 'Statistical',
  },
  {
    name: 'BETAINV',
    description: 'Returns the inverse of Beta distribution value.',
    syntax: 'BETAINV(Number1, Number2, Number3[, Number4[, Number5]])',
    section: 'Statistical',
  },
  {
    name: 'BINOM.DIST',
    description: 'Returns density of binomial distribution.',
    syntax: 'BINOM.DIST(Number1, Number2, Number3, Boolean)',
    section: 'Statistical',
  },
  {
    name: 'BINOMDIST',
    description: 'Returns density of binomial distribution.',
    syntax: 'BINOMDIST(Number1, Number2, Number3, Boolean)',
    section: 'Statistical',
  },
  {
    name: 'BINOM.INV',
    description: 'Returns inverse binomial distribution value.',
    syntax: 'BINOM.INV(Number1, Number2, Number3)',
    section: 'Statistical',
  },
  {
    name: 'CHIDIST',
    description: 'Returns probability of chi-square right-side distribution.',
    syntax: 'CHIDIST(X, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'CHIINV',
    description: 'Returns inverse of chi-square right-side distribution.',
    syntax: 'CHIINV(P, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'CHIINVRT',
    description: 'Returns inverse of chi-square right-side distribution.',
    syntax: 'CHIINVRT(P, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'CHISQ.DIST',
    description: 'Returns value of chi-square distribution.',
    syntax: 'CHISQ.DIST(X, Degrees, Mode)',
    section: 'Statistical',
  },
  {
    name: 'CHIDISTRT',
    description: 'Returns probability of chi-square right-side distribution.',
    syntax: 'CHIDISTRT(X, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'CHISQ.DIST.RT',
    description: 'Returns probability of chi-square right-side distribution.',
    syntax: 'CHISQ.DIST.RT(X, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'CHISQ.INV',
    description: 'Returns inverse of chi-square distribution.',
    syntax: 'CHISQ.INV.RT(P, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'CHISQ.INV.RT',
    description: 'Returns inverse of chi-square right-side distribution.',
    syntax: 'CHISQ.INV.RT(P, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'CHISQ.TEST',
    description: 'Returns chi-squared-test value for a dataset.',
    syntax: 'CHISQ.TEST(Array1, Array2)',
    section: 'Statistical',
  },
  {
    name: 'CHITEST',
    description: 'Returns chi-squared-test value for a dataset.',
    syntax: 'CHITEST(Array1, Array2)',
    section: 'Statistical',
  },
  {
    name: 'CONFIDENCE',
    description: 'Returns upper confidence bound for normal distribution.',
    syntax: 'CONFIDENCE(Alpha, Stdev, Size)',
    section: 'Statistical',
  },
  {
    name: 'CONFIDENCE.NORM',
    description: 'Returns upper confidence bound for normal distribution.',
    syntax: 'CONFIDENCE.NORM(Alpha, Stdev, Size)',
    section: 'Statistical',
  },
  {
    name: 'CONFIDENCE.T',
    description: 'Returns upper confidence bound for T distribution.',
    syntax: 'CONFIDENCE.T(Alpha, Stdev, Size)',
    section: 'Statistical',
  },
  {
    name: 'CORREL',
    description: 'Returns the correlation coefficient between two data sets.',
    syntax: 'CORREL(Data1, Data2)',
    section: 'Statistical',
  },
  {
    name: 'COUNT',
    description: 'Counts how many numbers are in the list of arguments.',
    syntax: 'COUNT(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'COUNTA',
    description: 'Counts how many values are in the list of arguments.',
    syntax: 'COUNTA(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'COUNTBLANK',
    description: 'Returns the number of empty cells.',
    syntax: 'COUNTBLANK(Range)',
    section: 'Statistical',
  },
  {
    name: 'COUNTIF',
    description: 'Returns the number of cells that meet with certain criteria within a cell range.',
    syntax: 'COUNTIF(Range, Criteria)',
    section: 'Statistical',
  },
  {
    name: 'COUNTIFS',
    description: 'Returns the count of rows or columns that meet criteria in multiple ranges.',
    syntax: 'COUNTIFS(Range1, Criterion1 [, Range2, Criterion2 [, ...RangeN, CriterionN]])',
    section: 'Statistical',
  },
  {
    name: 'COVAR',
    description: 'Returns the covariance between two data sets, population normalized.',
    syntax: 'COVAR(Data1, Data2)',
    section: 'Statistical',
  },
  {
    name: 'COVARIANCE.P',
    description: 'Returns the covariance between two data sets, population normalized.',
    syntax: 'COVARIANCE.P(Data1, Data2)',
    section: 'Statistical',
  },
  {
    name: 'COVARIANCEP',
    description: 'Returns the covariance between two data sets, population normalized.',
    syntax: 'COVARIANCEP(Data1, Data2)',
    section: 'Statistical',
  },
  {
    name: 'COVARIANCE.S',
    description: 'Returns the covariance between two data sets, sample normalized.',
    syntax: 'COVARIANCE.S(Data1, Data2)',
    section: 'Statistical',
  },
  {
    name: 'COVARIANCES',
    description: 'Returns the covariance between two data sets, sample normalized.',
    syntax: 'COVARIANCES(Data1, Data2)',
    section: 'Statistical',
  },
  {
    name: 'CRITBINOM',
    description: 'Returns inverse binomial distribution value.',
    syntax: 'CRITBINOM(Number1, Number2, Number3)',
    section: 'Statistical',
  },
  {
    name: 'DEVSQ',
    description: 'Returns sum of squared deviations.',
    syntax: 'DEVSQ(Number1, Number2, ...NumberN)',
    section: 'Statistical',
  },
  {
    name: 'EXPON.DIST',
    description: 'Returns density of an exponential distribution.',
    syntax: 'EXPON.DIST(Number1, Number2, Boolean)',
    section: 'Statistical',
  },
  {
    name: 'EXPONDIST',
    description: 'Returns density of an exponential distribution.',
    syntax: 'EXPONDIST(Number1, Number2, Boolean)',
    section: 'Statistical',
  },
  {
    name: 'FDIST',
    description: 'Returns probability of F right-side distribution.',
    syntax: 'FDIST(X, Degree1, Degree2)',
    section: 'Statistical',
  },
  {
    name: 'FINV',
    description: 'Returns inverse of F right-side distribution.',
    syntax: 'FINV(P, Degree1, Degree2)',
    section: 'Statistical',
  },
  {
    name: 'F.DIST',
    description: 'Returns value of F distribution.',
    syntax: 'F.DIST(X, Degree1, Degree2, Mode)',
    section: 'Statistical',
  },
  {
    name: 'F.DIST.RT',
    description: 'Returns probability of F right-side distribution.',
    syntax: 'F.DIST.RT(X, Degree1, Degree2)',
    section: 'Statistical',
  },
  {
    name: 'FDISTRT',
    description: 'Returns probability of F right-side distribution.',
    syntax: 'FDISTRT(X, Degree1, Degree2)',
    section: 'Statistical',
  },
  {
    name: 'F.INV',
    description: 'Returns inverse of F distribution.',
    syntax: 'F.INV.RT(P, Degree1, Degree2)',
    section: 'Statistical',
  },
  {
    name: 'F.INV.RT',
    description: 'Returns inverse of F right-side distribution.',
    syntax: 'F.INV.RT(P, Degree1, Degree2)',
    section: 'Statistical',
  },
  {
    name: 'FINVRT',
    description: 'Returns inverse of F right-side distribution.',
    syntax: 'FINVRT(P, Degree1, Degree2)',
    section: 'Statistical',
  },
  {
    name: 'FISHER',
    description: 'Returns Fisher transformation value.',
    syntax: 'FISHER(Number)',
    section: 'Statistical',
  },
  {
    name: 'FISHERINV',
    description: 'Returns inverse Fisher transformation value.',
    syntax: 'FISHERINV(Number)',
    section: 'Statistical',
  },
  {
    name: 'F.TEST',
    description: 'Returns f-test value for a dataset.',
    syntax: 'Z.TEST(Array1, Array2)',
    section: 'Statistical',
  },
  {
    name: 'FTEST',
    description: 'Returns f-test value for a dataset.',
    syntax: 'ZTEST(Array1, Array2)',
    section: 'Statistical',
  },
  {
    name: 'GAMMA',
    description: 'Returns value of Gamma function.',
    syntax: 'GAMMA(Number)',
    section: 'Statistical',
  },
  {
    name: 'GAMMA.DIST',
    description: 'Returns density of Gamma distribution.',
    syntax: 'GAMMA.DIST(Number1, Number2, Number3, Boolean)',
    section: 'Statistical',
  },
  {
    name: 'GAMMADIST',
    description: 'Returns density of Gamma distribution.',
    syntax: 'GAMMADIST(Number1, Number2, Number3, Boolean)',
    section: 'Statistical',
  },
  {
    name: 'GAMMALN',
    description: 'Returns natural logarithm of Gamma function.',
    syntax: 'GAMMALN(Number)',
    section: 'Statistical',
  },
  {
    name: 'GAMMALN.PRECISE',
    description: 'Returns natural logarithm of Gamma function.',
    syntax: 'GAMMALN.PRECISE(Number)',
    section: 'Statistical',
  },
  {
    name: 'GAMMA.INV',
    description: 'Returns inverse Gamma distribution value.',
    syntax: 'GAMMA.INV(Number1, Number2, Number3)',
    section: 'Statistical',
  },
  {
    name: 'GAMMAINV',
    description: 'Returns inverse Gamma distribution value.',
    syntax: 'GAMMAINV(Number1, Number2, Number3)',
    section: 'Statistical',
  },
  {
    name: 'GAUSS',
    description:
      'Returns the probability of gaussian variable fall more than this many times standard deviation from mean.',
    syntax: 'GAUSS(Number)',
    section: 'Statistical',
  },
  {
    name: 'GEOMEAN',
    description: 'Returns the geometric average.',
    syntax: 'GEOMEAN(Number1, Number2, ...NumberN)',
    section: 'Statistical',
  },
  {
    name: 'HARMEAN',
    description: 'Returns the harmonic average.',
    syntax: 'HARMEAN(Number1, Number2, ...NumberN)',
    section: 'Statistical',
  },
  {
    name: 'HYPGEOMDIST',
    description: 'Returns density of hypergeometric distribution.',
    syntax: 'HYPGEOMDIST(Number1, Number2, Number3, Number4, Boolean)',
    section: 'Statistical',
  },
  {
    name: 'HYPGEOM.DIST',
    description: 'Returns density of hypergeometric distribution.',
    syntax: 'HYPGEOM.DIST(Number1, Number2, Number3, Number4, Boolean)',
    section: 'Statistical',
  },
  {
    name: 'LARGE',
    description: 'Returns k-th largest value in a range.',
    syntax: 'LARGE(Range, K)',
    section: 'Statistical',
  },
  {
    name: 'LOGNORM.DIST',
    description: 'Returns density of lognormal distribution.',
    syntax: 'LOGNORM.DIST(X, Mean, Stddev, Mode)',
    section: 'Statistical',
  },
  {
    name: 'LOGNORMDIST',
    description: 'Returns density of lognormal distribution.',
    syntax: 'LOGNORMDIST(X, Mean, Stddev, Mode)',
    section: 'Statistical',
  },
  {
    name: 'LOGNORM.INV',
    description: 'Returns value of inverse lognormal distribution.',
    syntax: 'LOGNORM.INV(P, Mean, Stddev)',
    section: 'Statistical',
  },
  {
    name: 'LOGNORMINV',
    description: 'Returns value of inverse lognormal distribution.',
    syntax: 'LOGNORMINV(P, Mean, Stddev)',
    section: 'Statistical',
  },
  {
    name: 'LOGINV',
    description: 'Returns value of inverse lognormal distribution.',
    syntax: 'LOGINV(P, Mean, Stddev)',
    section: 'Statistical',
  },
  {
    name: 'MAX',
    description: 'Returns the maximum value in a list of arguments.',
    syntax: 'MAX(Number1, Number2, ...NumberN)',
    section: 'Statistical',
  },
  {
    name: 'MAXA',
    description: 'Returns the maximum value in a list of arguments.',
    syntax: 'MAXA(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'MAXIFS',
    description: 'Returns the maximum value of the cells in a range that meet a set of criteria.',
    syntax:
      'MAXIFS(Max_Range, Criterion_range1, Criterion1 [, Criterion_range2, Criterion2 [, ...Criterion_rangeN, CriterionN]])',
    section: 'Statistical',
  },
  {
    name: 'MEDIAN',
    description: 'Returns the median of a set of numbers.',
    syntax: 'MEDIAN(Number1, Number2, ...NumberN)',
    section: 'Statistical',
  },
  {
    name: 'MIN',
    description: 'Returns the minimum value in a list of arguments.',
    syntax: 'MIN(Number1, Number2, ...NumberN)',
    section: 'Statistical',
  },
  {
    name: 'MINA',
    description: 'Returns the minimum value in a list of arguments.',
    syntax: 'MINA(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'MINIFS',
    description: 'Returns the minimum value of the cells in a range that meet a set of criteria.',
    syntax:
      'MINIFS(Min_Range, Criterion_range1, Criterion1 [, Criterion_range2, Criterion2 [, ...Criterion_rangeN, CriterionN]])',
    section: 'Statistical',
  },
  {
    name: 'NEGBINOM.DIST',
    description: 'Returns density of negative binomial distribution.',
    syntax: 'NEGBINOM.DIST(Number1, Number2, Number3, Mode)',
    section: 'Statistical',
  },
  {
    name: 'NEGBINOMDIST',
    description: 'Returns density of negative binomial distribution.',
    syntax: 'NEGBINOMDIST(Number1, Number2, Number3, Mode)',
    section: 'Statistical',
  },
  {
    name: 'NORM.DIST',
    description: 'Returns density of normal distribution.',
    syntax: 'NORM.DIST(X, Mean, Stddev, Mode)',
    section: 'Statistical',
  },
  {
    name: 'NORMDIST',
    description: 'Returns density of normal distribution.',
    syntax: 'NORMDIST(X, Mean, Stddev, Mode)',
    section: 'Statistical',
  },
  {
    name: 'NORM.S.DIST',
    description: 'Returns density of normal distribution.',
    syntax: 'NORM.S.DIST(X, Mode)',
    section: 'Statistical',
  },
  {
    name: 'NORMDIST',
    description: 'Returns density of normal distribution.',
    syntax: 'NORMSDIST(X, Mode)',
    section: 'Statistical',
  },
  {
    name: 'NORM.INV',
    description: 'Returns value of inverse normal distribution.',
    syntax: 'NORM.INV(P, Mean, Stddev)',
    section: 'Statistical',
  },
  {
    name: 'NORMINV',
    description: 'Returns value of inverse normal distribution.',
    syntax: 'NORMINV(P, Mean, Stddev)',
    section: 'Statistical',
  },
  {
    name: 'NORM.S.INV',
    description: 'Returns value of inverse normal distribution.',
    syntax: 'NORM.S.INV(P)',
    section: 'Statistical',
  },
  {
    name: 'NORMSINV',
    description: 'Returns value of inverse normal distribution.',
    syntax: 'NORMSINV(P)',
    section: 'Statistical',
  },
  {
    name: 'PEARSON',
    description: 'Returns the correlation coefficient between two data sets.',
    syntax: 'PEARSON(Data1, Data2)',
    section: 'Statistical',
  },
  {
    name: 'PHI',
    description: 'Returns probability density of normal distribution.',
    syntax: 'PHI(X)',
    section: 'Statistical',
  },
  {
    name: 'POISSON',
    description: 'Returns density of Poisson distribution.',
    syntax: 'POISSON(X, Mean, Mode)',
    section: 'Statistical',
  },
  {
    name: 'POISSON.DIST',
    description: 'Returns density of Poisson distribution.',
    syntax: 'POISSON.DIST(X, Mean, Mode)',
    section: 'Statistical',
  },
  {
    name: 'POISSONDIST',
    description: 'Returns density of Poisson distribution.',
    syntax: 'POISSONDIST(X, Mean, Mode)',
    section: 'Statistical',
  },
  {
    name: 'RSQ',
    description: 'Returns the squared correlation coefficient between two data sets.',
    syntax: 'RSQ(Data1, Data2)',
    section: 'Statistical',
  },
  {
    name: 'SKEW',
    description: 'Returns skewness of a sample.',
    syntax: 'SKEW(Number1, Number2, ...NumberN)',
    section: 'Statistical',
  },
  {
    name: 'SKEW.P',
    description: 'Returns skewness of a population.',
    syntax: 'SKEW.P(Number1, Number2, ...NumberN)',
    section: 'Statistical',
  },
  {
    name: 'SKEWP',
    description: 'Returns skewness of a population.',
    syntax: 'SKEWP(Number1, Number2, ...NumberN)',
    section: 'Statistical',
  },
  {
    name: 'SLOPE',
    description: 'Returns the slope of a linear regression line.',
    syntax: 'SLOPE(Array1, Array2)',
    section: 'Statistical',
  },
  {
    name: 'SMALL',
    description: 'Returns k-th smallest value in a range.',
    syntax: 'SMALL(Range, K)',
    section: 'Statistical',
  },
  {
    name: 'STANDARDIZE',
    description: 'Returns normalized value with respect to expected value and standard deviation.',
    syntax: 'STANDARDIZE(X, Mean, Stddev)',
    section: 'Statistical',
  },
  {
    name: 'STDEV',
    description: 'Returns standard deviation of a sample.',
    syntax: 'STDEV(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'STDEVA',
    description: 'Returns standard deviation of a sample.',
    syntax: 'STDEVA(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'STDEVP',
    description: 'Returns standard deviation of a population.',
    syntax: 'STDEVP(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'STDEV.P',
    description: 'Returns standard deviation of a population.',
    syntax: 'STDEV.P(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'STDEVPA',
    description: 'Returns standard deviation of a population.',
    syntax: 'STDEVPA(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'STDEV.S',
    description: 'Returns standard deviation of a sample.',
    syntax: 'STDEV.S(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'STDEVS',
    description: 'Returns standard deviation of a sample.',
    syntax: 'STDEVS(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'STEYX',
    description: 'Returns standard error for predicted of the predicted y value for each x value.',
    syntax: 'STEYX(Array1, Array2)',
    section: 'Statistical',
  },
  {
    name: 'TDIST',
    description: 'Returns density of Student-t distribution, both-sided or right-tailed.',
    syntax: 'TDIST(X, Degrees, Mode)',
    section: 'Statistical',
  },
  {
    name: 'T.DIST',
    description: 'Returns density of Student-t distribution.',
    syntax: 'T.DIST(X, Degrees, Mode)',
    section: 'Statistical',
  },
  {
    name: 'T.DIST.2T',
    description: 'Returns density of Student-t distribution, both-sided.',
    syntax: 'T.DIST.2T(X, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'TDIST2T',
    description: 'Returns density of Student-t distribution, both-sided.',
    syntax: 'TDIST2T(X, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'T.DIST.RT',
    description: 'Returns density of Student-t distribution, right-tailed.',
    syntax: 'T.DIST.RT(X, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'TDISTRT',
    description: 'Returns density of Student-t distribution, right-tailed.',
    syntax: 'TDISTRT(X, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'TINV',
    description: 'Returns inverse Student-t distribution, both-sided.',
    syntax: 'TINV(P, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'T.INV',
    description: 'Returns inverse Student-t distribution.',
    syntax: 'T.INV(P, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'T.INV.2T',
    description: 'Returns inverse Student-t distribution, both-sided.',
    syntax: 'T.INV.2T(P, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'TINV2T',
    description: 'Returns inverse Student-t distribution, both-sided.',
    syntax: 'TINV2T(P, Degrees)',
    section: 'Statistical',
  },
  {
    name: 'TTEST',
    description: 'Returns t-test value for a dataset.',
    syntax: 'TTEST(Array1, Array2)',
    section: 'Statistical',
  },
  {
    name: 'T.TEST',
    description: 'Returns t-test value for a dataset.',
    syntax: 'T.TEST(Array1, Array2)',
    section: 'Statistical',
  },
  {
    name: 'VAR',
    description: 'Returns variance of a sample.',
    syntax: 'VAR(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'VARA',
    description: 'Returns variance of a sample.',
    syntax: 'VARA(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'VARP',
    description: 'Returns variance of a population.',
    syntax: 'VARP(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'VAR.P',
    description: 'Returns variance of a population.',
    syntax: 'VAR.P(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'VARPA',
    description: 'Returns variance of a population.',
    syntax: 'VARPA(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'VAR.S',
    description: 'Returns variance of a sample.',
    syntax: 'VAR.S(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'VARS',
    description: 'Returns variance of a sample.',
    syntax: 'VARS(Value1, Value2, ...ValueN)',
    section: 'Statistical',
  },
  {
    name: 'WEIBULL',
    description: 'Returns density of Weibull distribution.',
    syntax: 'WEIBULL(Number1, Number2, Number3, Boolean)',
    section: 'Statistical',
  },
  {
    name: 'WEIBULL.DIST',
    description: 'Returns density of Weibull distribution.',
    syntax: 'WEIBULL.DIST(Number1, Number2, Number3, Boolean)',
    section: 'Statistical',
  },
  {
    name: 'WEIBULLDIST',
    description: 'Returns density of Weibull distribution.',
    syntax: 'WEIBULLDIST(Number1, Number2, Number3, Boolean)',
    section: 'Statistical',
  },
  {
    name: 'Z.TEST',
    description: 'Returns z-test value for a dataset.',
    syntax: 'Z.TEST(Array, X[, Sigma])',
    section: 'Statistical',
  },
  {
    name: 'ZTEST',
    description: 'Returns z-test value for a dataset.',
    syntax: 'ZTEST(Array, X[, Sigma])',
    section: 'Statistical',
  },

  // Text

  {
    name: 'CHAR',
    description: 'Converts a number into a character according to the current code table.',
    syntax: 'CHAR(Number)',
    section: 'Text',
  },
  {
    name: 'CLEAN',
    description: 'Returns text that has been "cleaned" of line breaks and other non-printable characters.',
    syntax: 'CLEAN("Text")',
    section: 'Text',
  },
  {
    name: 'CODE',
    description: 'Returns a numeric code for the first character in a text string.',
    syntax: 'CODE("Text")',
    section: 'Text',
  },
  {
    name: 'CONCATENATE',
    description: 'Combines several text strings into one string.',
    syntax: 'CONCATENATE("Text1", "Text2", ..."TextN")',
    section: 'Text',
  },
  {
    name: 'EXACT',
    description: 'Returns TRUE if both text strings are exactly the same.',
    syntax: 'EXACT(Text, Text)',
    section: 'Text',
  },
  {
    name: 'FIND',
    description: 'Returns the location of one text string inside another.',
    syntax: 'FIND( "Text1", "Text2"[, Number])',
    section: 'Text',
  },
  {
    name: 'LEFT',
    description: 'Extracts a given number of characters from the left side of a text string.',
    syntax: 'LEFT("Text", Number)',
    section: 'Text',
  },
  {
    name: 'LEN',
    description: 'Returns length of a given text.',
    syntax: 'LEN("Text")',
    section: 'Text',
  },
  {
    name: 'LOWER',
    description: 'Returns text converted to lowercase.',
    syntax: 'LOWER(Text)',
    section: 'Text',
  },
  {
    name: 'MID',
    description: 'Returns substring of a given length starting from Start_position.',
    syntax: 'MID(Text, Start_position, Length)',
    section: 'Text',
  },
  {
    name: 'PROPER',
    description: 'Capitalizes words given text string.',
    syntax: 'PROPER("Text")',
    section: 'Text',
  },
  {
    name: 'REPLACE',
    description: 'Replaces substring of a text of a given length that starts at given position.',
    syntax: 'REPLACE(Text, Start_position, Length, New_text)',
    section: 'Text',
  },
  {
    name: 'REPT',
    description: 'Repeats text a given number of times.',
    syntax: 'REPT("Text", Number)',
    section: 'Text',
  },
  {
    name: 'RIGHT',
    description: 'Extracts a given number of characters from the right side of a text string.',
    syntax: 'RIGHT("Text", Number)',
    section: 'Text',
  },
  {
    name: 'SEARCH',
    description: 'Returns the location of Search_string inside Text. Case-insensitive. Allows the use of wildcards.',
    syntax: 'SEARCH(Search_string, Text[, Start_position])',
    section: 'Text',
  },
  {
    name: 'SPLIT',
    description:
      'Divides the provided text using the space character as a separator and returns the substring at the zero-based position specified by the second argument.',
    syntax: 'SPLIT(Text, Index)',
    section: 'Text',
  },
  {
    name: 'SUBSTITUTE',
    description:
      'Returns string where occurrences of Old_text are replaced by New_text. Replaces only specific occurrence if last parameter is provided.',
    syntax: 'SUBSTITUTE(Text, Old_text, New_text, [Occurrence])',
    section: 'Text',
  },
  {
    name: 'T',
    description: 'Returns text if given value is text, empty string otherwise.',
    syntax: 'T(Value)',
    section: 'Text',
  },
  {
    name: 'TEXT',
    description: 'Converts a number into text according to a given format.',
    syntax: 'TEXT(Number, Format)',
    section: 'Text',
  },
  {
    name: 'TRIM',
    description: 'Strips extra spaces from text.',
    syntax: 'TRIM("Text")',
    section: 'Text',
  },
  {
    name: 'UNICHAR',
    description: 'Returns the character created by using provided code point.',
    syntax: 'UNICHAR(Number)',
    section: 'Text',
  },
  {
    name: 'UNICODE',
    description: 'Returns the Unicode code point of a first character of a text.',
    syntax: 'UNICODE(Text)',
    section: 'Text',
  },
  {
    name: 'UPPER',
    description: 'Returns text converted to uppercase.',
    syntax: 'UPPER(Text)',
    section: 'Text',
  },
];
