import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBN = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2048 2048' {...props}>
    <path
      fill='#f9f9f9'
      d='M1658 1806c-408-301-71-920-452-1343l60-16s296 167 320 257c182 468 41 691 135 1077zM972 523l120 176-312-3zM498 981l-93-41 42-93 127 28z'
    />
    <path
      fill='#101010'
      d='m502 868-52 1-26 64 69 21 46-55zm536-187q34 1-16-68t-80-42L826 680zm-338-98q6-39 116-107t220-144l115-154 96 217q342 172 433 418t47 603q-18 128 5 236t57 190l-1242 1q-9-178 39-301t183-238q50-11 83-39t53-59l63-1 138-29 139-97 66-207q0-17-8-34t-12-37q-62 228-161 289t-191 58q-236-42-292 60l-56 102-217-121 115-82-51-50-122 86-12-297zm981 1192q-102-130-85-308t27-363-50-351-316-276q220 164 253 342t16 351-12 329 167 276'
    />
  </svg>
);
export default SvgBN;
